<template>
  <div v-if="!loading">
    <v-form
      @submit.stop.prevent="get_requests_by_page()"
      ref="form"
      v-model="valid"
      :lazy-validation="lazy"
    >
      <v-btn
        color="primary"
        rounded
        @click="download_allocations_report"
        style="right: 65px"
        :loading="reportDownloading"
        absolute
        small
        fab
        top
        right
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <v-btn
        data-test="Vehicle:UserInterest:BtnANewRequest"
        color="primary"
        rounded
        @click="open_edit_dialog(null)"
        absolute
        small
        fab
        top
        right
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-row v-if="filter" justify="center" class="mt-6">
        <v-col cols="12" sm="12" md="4">
          <v-text-field
            dense
            outlined
            :label="$t('name') + '/' + $t('email')"
            v-model="name_filter"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="3" lg="4">
          <v-text-field
            dense
            outlined
            :label="$tc('company', 1)"
            v-model="company_filter"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="3">
          <v-select
            dense
            outlined
            :items="StatusFilterOptions"
            item-text="text"
            item-value="index"
            v-model="status_filter"
            :label="$t('status')"
          >
          </v-select>
        </v-col>
        <v-col cols="12" sm="12" md="2" lg="1" class="text-center">
          <v-btn class="secondary" type="submit">{{ $t("apply") }}</v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-simple-table>
      <thead>
        <tr>
          <th scope="col" class="table-head">
            {{ $t("name")
            }}<v-icon
              small
              :color="orderBy == orderFilterEnum.NAME ? 'primary' : ''"
              class="ml-2"
              @click="header_click(orderFilterEnum.NAME)"
              >{{
                orderByMode == orderByModeEnum.ASC
                  ? "mdi-arrow-up"
                  : "mdi-arrow-down"
              }}</v-icon
            >
          </th>
          <th scope="col" class="table-head">
            {{ $t("email")
            }}<v-icon
              small
              :color="orderBy == orderFilterEnum.EMAIL ? 'primary' : ''"
              class="ml-2"
              @click="header_click(orderFilterEnum.EMAIL)"
              >{{
                orderByMode == orderByModeEnum.ASC
                  ? "mdi-arrow-up"
                  : "mdi-arrow-down"
              }}</v-icon
            >
          </th>
          <th scope="col" class="table-head">
            {{ $tc("background_check", 1) }}
            <v-icon
              small
              :color="
                orderBy == orderFilterEnum.BACKGROUND_CHECK ? 'primary' : ''
              "
              class="ml-2"
              @click="header_click(orderFilterEnum.BACKGROUND_CHECK)"
              >{{
                orderByMode == orderByModeEnum.ASC
                  ? "mdi-arrow-up"
                  : "mdi-arrow-down"
              }}</v-icon
            >
          </th>
          <th scope="col" class="table-head">
            {{ $tc("partner", 1)
            }}<v-icon
              small
              :color="orderBy == orderFilterEnum.PARTNER ? 'primary' : ''"
              class="ml-2"
              @click="header_click(orderFilterEnum.PARTNER)"
              >{{
                orderByMode == orderByModeEnum.ASC
                  ? "mdi-arrow-up"
                  : "mdi-arrow-down"
              }}</v-icon
            >
          </th>
          <th scope="col" class="table-head">
            {{ $tc("company", 1)
            }}<v-icon
              small
              :color="orderBy == orderFilterEnum.COMPANY ? 'primary' : ''"
              class="ml-2"
              @click="header_click(orderFilterEnum.COMPANY)"
              >{{
                orderByMode == orderByModeEnum.ASC
                  ? "mdi-arrow-up"
                  : "mdi-arrow-down"
              }}</v-icon
            >
          </th>
          <th scope="col" class="table-head">
            {{ $tc("vehicle_title_single") }}
            <v-icon small class="ml-2">{{
              orderByMode == orderByModeEnum.ASC
                ? "mdi-arrow-up"
                : "mdi-arrow-down"
            }}</v-icon>
          </th>
          <th scope="col" class="table-head">
            {{ $t("date")
            }}<v-icon
              small
              :color="orderBy == orderFilterEnum.DATE ? 'primary' : ''"
              class="ml-2"
              @click="header_click(orderFilterEnum.DATE)"
              >{{
                orderByMode == orderByModeEnum.ASC
                  ? "mdi-arrow-up"
                  : "mdi-arrow-down"
              }}</v-icon
            >
          </th>
          <th scope="col" class="table-head">
            {{ $t("value")
            }}<v-icon
              small
              :color="orderBy == orderFilterEnum.VALUE ? 'primary' : ''"
              class="ml-2"
              @click="header_click(orderFilterEnum.VALUE)"
              >{{
                orderByMode == orderByModeEnum.ASC
                  ? "mdi-arrow-up"
                  : "mdi-arrow-down"
              }}</v-icon
            >
          </th>
          <th scope="col" class="table-head">
            {{ $t("status")
            }}<v-icon
              small
              :color="orderBy == orderFilterEnum.STATUS ? 'primary' : ''"
              class="ml-2"
              @click="header_click(orderFilterEnum.STATUS)"
              >{{
                orderByMode == orderByModeEnum.ASC
                  ? "mdi-arrow-up"
                  : "mdi-arrow-down"
              }}</v-icon
            >
          </th>

          <th scope="col" class="text-center table-head">
            {{ $t("commitment_term") }}
          </th>
          <th scope="col" class="text-center table-head">
            {{ $t("options") }}
          </th>
          <th scope="col" class="table-head">
            {{ $t("status_description")
            }}<v-icon
              small
              :color="
                orderBy == orderFilterEnum.STATUS_DESCRIPTION ? 'primary' : ''
              "
              class="ml-2"
              @click="header_click(orderFilterEnum.STATUS_DESCRIPTION)"
              >{{
                orderByMode == orderByModeEnum.ASC
                  ? "mdi-arrow-up"
                  : "mdi-arrow-down"
              }}</v-icon
            >
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(req, index) in requests" :key="index">
          <a @click="redirect_user(req.User)">
            <td>
              {{ show_name(req.User) }}
              <v-tooltip bottom v-if="req.User.IsAlpha">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-on="on" v-bind="attrs" color="secondary"
                    >mdi-alpha</v-icon
                  >
                </template>
                <span>{{ $t("alpha_user") }}</span>
              </v-tooltip>
            </td>
          </a>
          <td>{{ req.User.Email }}</td>
          <td>
            {{
              req.User.BackgroundChecks
                ? get_bc_result(req.User.BackgroundChecks[0])
                : "-"
            }}
          </td>
          <td>
            {{
              req.User.PartnerUser
                ? show_name(req.User.PartnerUser.Partner)
                : "-"
            }}
          </td>
          <td>
            {{ req.Company.Name }}
          </td>
          <td v-if="req.InvestmentVehicle">
            {{ req.InvestmentVehicle.Name }}
          </td>
          <td v-else>-</td>
          <td>{{ convert_date(req.Timestamp) }}</td>
          <td>{{ formatToCurrency(req.Currency, req.Value) }}</td>
          <td>{{ $t(`${parseRequestStatus(req.Status)}`) }}</td>
          <td class="text-center">
            <v-btn
              small
              v-if="getCommitmentTerm(req.Files)"
              min-width="0"
              class="mx-1 secondary"
              @click="get_doc_path(getCommitmentTerm(req.Files))"
              :loading="fileDownloading == getCommitmentTerm(req.Files).Path"
            >
              <v-icon>mdi-file-document</v-icon>
            </v-btn>
          </td>
          <td class="text-center">
            <v-btn
              :data-test="`Vehicle:UserInterest:BtnEdit:${req.Value}`"
              color="secondary"
              class="mx-1"
              @click="open_edit_dialog(req)"
              min-width="0"
              small
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              v-if="showApproveButton(req.Status)"
              class="mx-1"
              @click="open_delete_dialog(req, 0)"
              min-width="0"
              color="primary"
              small
            >
              <v-icon small>mdi-check-bold</v-icon>
            </v-btn>
            <v-btn
              :data-test="`Vehicle:UserInterest:BtnDelete:${req.Value}`"
              v-if="req.Status != 2"
              class="mx-1"
              @click="open_delete_dialog(req, 1)"
              min-width="0"
              color="red"
              small
            >
              <v-icon small>mdi-close-thick</v-icon>
            </v-btn>
          </td>
          <td>
            <div v-if="req.StatusDescription">
              <p
                :class="`status-description ${
                  req.StatusDescription.length > 120 &&
                  'status-description-max-size'
                }`"
              >
                {{ req.StatusDescription }}
              </p>
              <span
                @click="
                  () => {
                    dialogFullStatusDescription = {
                      show: true,
                      content: req.StatusDescription,
                    };
                  }
                "
                v-if="req.StatusDescription.length > 120"
                class="read-more-status-description"
                >{{ $t("read_more") }}</span
              >
            </div>
            <span v-else>-</span>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-pagination
      color="secondary"
      @input="changePage"
      :length="total_pages"
      v-model="page"
    >
    </v-pagination>
    <v-dialog v-if="dialog" v-model="dialog" scrollable>
      <AddUserInterestModal
        :Interest="request"
        @close="dialog = false"
        @update="requests_updated"
        @reload="updatePage"
      ></AddUserInterestModal>
    </v-dialog>
    <v-dialog v-if="delete_dialog" v-model="delete_dialog">
      <DeleteConfirmationModal
        :localLoading="loadingRequestModal"
        :useLocalLoading="true"
        :obj="request"
        :CustomMessage="customMessage"
        @close="delete_dialog = false"
        @delete="change_status"
      ></DeleteConfirmationModal>
    </v-dialog>
    <v-dialog max-width="564" v-model="alertRequest">
      <AlertApproveRequest @closeDialog="alertRequest = false" />
    </v-dialog>
    <v-dialog max-width="564" v-model="dialogFullStatusDescription.show">
      <v-card :class="gs.isMobile() ? '' : 'dxa_modal'">
        <v-card-title class="mb-2">
          <h4 class="dxa_modal_title h4">{{ $t("status_description") }}</h4>
        </v-card-title>
        <v-card-text>
          <p class="status-description-full-text">
            {{ dialogFullStatusDescription.content }}
          </p>

          <v-card-actions class="pl-0 dxa_modal_actions">
            <v-btn
              @click="
                () => {
                  dialogFullStatusDescription = {
                    show: false,
                    content: null,
                  };
                }
              "
              class="dxa_modal_btnError"
              color="secondary"
              min-width="100"
            >
              {{ $t("close") }}
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
  <v-container v-else>
    <v-progress-circular
      style="margin-left: 50%"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>
<style>
.table-head {
  font-size: 14px !important;
  font-weight: bold !important;
}

.status-description-max-size {
  max-width: 220px;
  max-height: 60px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.status-description {
  margin-bottom: 0px !important;
}

.read-more-status-description {
  display: block;
  font-size: 14px;
  text-decoration: underline;
  color: var(--primary);
  cursor: pointer;
  padding-bottom: 4px;
  padding-top: 4px;
  max-width: max-content;
  border-radius: 4px;
}

.read-more-status-description:hover {
  font-weight: bold;
}

.status-description-full-text {
  font-size: 16px;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 8px;
}

tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}

.v-data-table-header {
  background-color: rgba(182, 183, 187);
  color: var(--white);
}

.v-data-footer {
  background-color: rgb(250, 250, 250);
}

.theme--light.v-data-table thead tr th {
  color: var(--white);
}
</style>
<script>
import ApiService from "@/services/ApiService";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";
import AddUserInterestModal from "../../form/AddUserInterestModal";
import moment from "moment";
import { BackGroundCheckResultEnum } from "@/shared/enums/BackgroundCheckResult.js";
import AlertApproveRequest from "../../components/AlertApproveRequest";
import { formatCurrency } from "@/shared/helpers/currencyHelper";
import { allocationStatusEnums } from "../../../allocations/AllocationStatusEnums";
import { userFileEnums } from "@/shared/enums/UserFileEnums";

const OrderFilterEnum = {
  NAME: 0,
  EMAIL: 1,
  PARTNER: 2,
  COMPANY: 3,
  DATE: 4,
  VALUE: 5,
  STATUS: 6,
  BACKGROUND_CHECK: 7,
  STATUS_DESCRIPTION: 8,
};
const OrderByModeEnum = {
  ASC: 0,
  DESC: 1,
};
import GeneralServices from "@/services/GeneralServices";

export default {
  name: "UserAllocationRequests",

  components: {
    DeleteConfirmationModal,
    AddUserInterestModal,
    AlertApproveRequest,
  },

  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    loading: false,
    filter: true,
    customMessage: null,
    tab: 0,
    page: 1,
    total_pages: 1,
    name_filter: "",
    company_filter: "",
    status_filter: -1,
    orderBy: OrderFilterEnum.DATE,
    orderByMode: OrderByModeEnum.DESC,
    orderFilterEnum: OrderFilterEnum,
    orderByModeEnum: OrderByModeEnum,
    valid: true,
    lazy: false,
    reportDownloading: false,
    request: null,
    response: null,
    dialog: false,
    delete_dialog: false,
    fileDownloading: null,
    requests: [],
    alertRequest: false,
    loadingRequestModal: true,
    formatToCurrency: formatCurrency,
    dialogFullStatusDescription: {
      show: false,
      content: null,
    },
    userFileEnums,
  }),
  async created() {
    this.get_requests_by_page();
  },
  computed: {
    StatusFilterOptions() {
      return [
        { text: this.$t("no_preference"), index: -1 },
        { text: this.$tc("interest_status_pending", 1), index: 0 },
        { text: this.$tc("interest_status_approved", 1), index: 1 },
        { text: this.$tc("interest_status_declined", 1), index: 2 },
        { text: this.$t("interest_status_waiting_investor"), index: 3 },
      ];
    },

    ResponseOptions() {
      return [this.$t("confirm"), this.$t("decline")];
    },
  },
  methods: {
    parseRequestStatus(requestStatus) {
      const literal = {
        [allocationStatusEnums.PLACEMENT_REVIEW]: "interest_status_pending",
        [allocationStatusEnums.FORMALIZATION]: "interest_status_approved",
        [allocationStatusEnums.DENIED]: "interest_status_declined",
        [allocationStatusEnums.PLACEMENT_REQUEST]:
          "interest_status_waiting_investor",
        [allocationStatusEnums.PENDING_COMMITMENT_TERM_SIGNED]:
          "interest_status_approved",
        [allocationStatusEnums.PENDING_INVESTMENT_PROXY_SIGNED]:
          "interest_status_approved",
        [allocationStatusEnums.TRANSFER_FUNDS]: "interest_status_approved",
        [allocationStatusEnums.OPERATION_SETTING]: "interest_status_approved",
        [allocationStatusEnums.SENT_CAPTABLE]: "interest_status_approved",
        [allocationStatusEnums.CANCELLED]: "interest_status_declined",
      };
      return literal[requestStatus];
    },
    showApproveButton(status) {
      const literal = {
        [allocationStatusEnums.PLACEMENT_REVIEW]: true,
        [allocationStatusEnums.FORMALIZATION]: false,
        [allocationStatusEnums.DENIED]: true,
        [allocationStatusEnums.PLACEMENT_REQUEST]: true,
        [allocationStatusEnums.PENDING_COMMITMENT_TERM_SIGNED]: false,
        [allocationStatusEnums.PENDING_INVESTMENT_PROXY_SIGNED]: false,
        [allocationStatusEnums.TRANSFER_FUNDS]: false,
        [allocationStatusEnums.OPERATION_SETTING]: false,
        [allocationStatusEnums.SENT_CAPTABLE]: false,
        [allocationStatusEnums.CANCELLED]: true,
      };
      return literal[status];
    },
    redirect_user(user) {
      this.$router.push({
        path: `/users`,
        query: { user_full_name: user.Name.concat(" ").concat(user.LastName) },
      });
    },
    get_bc_result(bc) {
      if (bc == null) {
        return "-";
      }
      const result = {
        [BackGroundCheckResultEnum.NOT_REVIEWED]: this.$t("not_reviewed"),
        [BackGroundCheckResultEnum.REVIEW]: this.$t("review"),
        [BackGroundCheckResultEnum.INVALID]: this.$t("invalid"),
        [BackGroundCheckResultEnum.VALID]: this.$t("valid"),
      };
      return result[bc.Result];
    },
    async download_allocations_report() {
      this.reportDownloading = true;
      await this.apiService
        .getRequest("companyinterest/generate-allocations-report")
        .then((resp) => {
          resp = "data:application/xlsx;base64," + resp.message;
          const link = document.createElement("a");
          link.href = resp;
          link.setAttribute(
            "download",
            `Allocations Report - ${moment().format("DD-MM-YYYY")}.xlsx`
          );
          document.body.appendChild(link);
          link.click();
        });
      this.reportDownloading = false;
    },
    getCommitmentTerm(fileList) {
      return fileList.find(
        (item) => item.UserFileType === this.userFileEnums.COMMITMENT_TERM
      );
    },
    get_doc_path(req) {
      this.fileDownloading = req.Path;

      this.apiService
        .getRequest(`investor/file?id=${req.Id}`)
        .then((resp) => {
          resp =
            "data:application/octet-stream;base64," + resp.content.fileContent;
          const link = document.createElement("a");
          link.href = resp;
          link.setAttribute("download", this.$t("commitment_term_pdf"));
          document.body.appendChild(link);
          link.click();
          this.fileDownloading = null;
        })
        .catch((error) => {});
    },
    convert_date(date) {
      var m_date = moment(date);
      return m_date.format("DD/MM/YYYY");
    },
    open_edit_dialog(request) {
      this.request = request;
      this.dialog = true;
    },
    requests_updated(request) {
      if (request == null) {
        this.get_requests_by_page();
      } else {
        this.request.Value = request.Value;
      }
      this.dialog = false;
    },
    header_click(col) {
      this.orderBy = col;
      if (this.orderByMode == 0) {
        this.orderByMode = 1;
      } else {
        this.orderByMode = 0;
      }
      this.get_requests_by_page();
    },
    format_to_currency: function (value) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "BRL",
      });
      return formatter.format(value);
    },
    async get_requests_by_page() {
      this.loading = true;
      var page = this.page;
      if (this.$route.query.page) {
        page = parseInt(this.$route.query.page);
      }
      var params = { Page: page };
      if (this.name_filter) {
        params.NameFilter = this.name_filter;
      }
      if (this.company_filter) {
        params.CompanyFilter = this.company_filter;
      }
      params.Type = this.status_filter;
      if (this.orderBy != null) {
        params.Order = this.orderBy;
        params.OrderMode = this.orderByMode;
      }
      await this.apiService
        .postRequest("companyinterest/list/filter", params)
        .then((resp) => {
          var json = JSON.parse(resp.message);
          this.page = json.Pager.CurrentPage;
          this.total_pages = json.Pager.TotalPages;
          this.requests = json.Interests;
        });
      this.loading = false;
      this.dialog = false;
    },
    changePage() {
      this.$router.push({
        path: "/requests",
        query: { page: this.page },
      });
      this.get_requests_by_page();
    },
    open_delete_dialog: function (request, resp) {
      this.delete_dialog = true;
      this.response = resp + 1;
      this.customMessage = this.$t("cofirmation_response", {
        resp: this.ResponseOptions[resp].toLowerCase(),
      });
      this.request = request;
    },
    show_name: function (user) {
      if (user.Name === null) {
        return "-";
      } else {
        return user.Name.concat(" ").concat(user.LastName);
      }
    },
    change_status: async function (request) {
      this.loadingRequestModal = true;
      let payload = {
        id: request.Id,
        status: this.response,
      };

      await this.apiService
        .putRequest("companyinterest/reply", payload)
        .then((resp) => {
          this.updatePage();
          request.Status = resp.content;
          this.$toast.success(this.$t("saved"));
        })
        .catch((error) => {
          if (error.status === 400) {
            this.alertRequest = true;
          } else {
            this.$toast.success(this.$t("an_error_ocurred"));
          }
        });
      this.delete_dialog = false;
    },
    updatePage() {
      this.get_requests_by_page();
    },
  },
};
</script>
