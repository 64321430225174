import { render, staticRenderFns } from "./BookingConfirmationModal.vue?vue&type=template&id=445d2f16&scoped=true&"
import script from "./BookingConfirmationModal.vue?vue&type=script&lang=js&"
export * from "./BookingConfirmationModal.vue?vue&type=script&lang=js&"
import style0 from "./BookingConfirmationModal.vue?vue&type=style&index=0&id=445d2f16&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "445d2f16",
  null
  
)

export default component.exports