<template>
  <v-form @submit.stop.prevent="checkForm()" ref="form" class="mt-5">
    <v-row align="center" class="ml-0 pb-5">
      <div>
        <span class="register-links-title"> {{ $t("alpha_register") }} </span>
        <ul class="register-links-list">
          <li v-if="alphaRegisterUrlPt" class="flex-center">
            <div class="flex-center">
              <span class="block mr-2 link-label"
                >{{ $t("br_investor") }}:</span
              >
              <span>{{ alphaRegisterUrlPt }}</span>
            </div>
            <v-btn
              x-small
              icon
              class="ml-3"
              @click="gs.copy_url_to_clipboard(alphaRegisterUrlPt)"
            >
              <v-icon color="primary">mdi-content-copy</v-icon>
            </v-btn>
          </li>
          <li v-if="alphaRegisterUrlEn" class="flex-center">
            <div class="flex-center">
              <span class="block mr-2 link-label"
                >{{ $t("us_investor") }}:</span
              >
              <span>{{ alphaRegisterUrlEn }}</span>
            </div>

            <v-btn
              x-small
              icon
              class="ml-3"
              @click="gs.copy_url_to_clipboard(alphaRegisterUrlEn)"
            >
              <v-icon color="primary">mdi-content-copy</v-icon>
            </v-btn>
          </li>
        </ul>
      </div>
    </v-row>
  </v-form>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import { LanguageTextEnum } from "@/shared/enums/LanguageEnum";
import { getUrlByLang } from "@/shared/helpers/languageHelper.js";

export default {
  name: "ManageUserBanker",
  components: {},

  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    loading: false,
    user: {},
    LanguageTextEnum,
    alphaRegisterUrlPt: null,
    alphaRegisterUrlEn: null,
  }),

  computed: {},
  props: {
    user_prop: Object,
  },

  async created() {
    this.alert_msg = null;
    if (this.user_prop != null) {
      const { AlphaRegisterUrlsLang } = this.user_prop;
      this.user = this.user_prop;

      if (AlphaRegisterUrlsLang && AlphaRegisterUrlsLang.length) {
        this.alphaRegisterUrlPt = getUrlByLang(
          AlphaRegisterUrlsLang,
          LanguageTextEnum.PT
        );

        this.alphaRegisterUrlEn = getUrlByLang(
          AlphaRegisterUrlsLang,
          LanguageTextEnum.EN
        );
      }
    }
  },

  methods: {},
};
</script>

<style scoped>
.register-links-title {
  font-size: 24px;
  font-weight: 500;
  display: block;
  margin-bottom: 20px;
}

.register-links-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.flex-center {
  display: flex;
  align-items: center;
}

.gap-5 {
  gap: 20px;
}

.gap-3 {
  gap: 12px;
}

.link-label {
  font-size: 16px;
  color: var(--primary);
}
</style>
